import { ConfigurationPanelContextProvider } from '@checkout-ui/demo-shop-configuration-panel';
import { ViewportContextProvider } from '@checkout-ui/demo-shop-viewport-toggler';
import LocaleContextProvider, {
  LocaleType,
  Messages,
} from '@checkout-ui/shared-context-locale';
import { CountryType } from '@checkout-ui/shared-domain-entities';
import { PropsWithChildren } from 'react';

import de_AT_messages from '../resources/i18n/de-AT.json';
import de_CH_messages from '../resources/i18n/de-CH.json';
import de_DE_messages from '../resources/i18n/de-DE.json';
import en_GB_messages from '../resources/i18n/en-GB.json';
import en_US_messages from '../resources/i18n/en-US.json';
import fi_FI_messages from '../resources/i18n/fi-FI.json';
import fr_FR_messages from '../resources/i18n/fr-FR.json';
import nb_NO_messages from '../resources/i18n/nb-NO.json';
import nl_NL_messages from '../resources/i18n/nl-NL.json';
import sv_SE_messages from '../resources/i18n/sv-SE.json';

const allAppMessages: Messages = {
  en: en_US_messages,
  'en-US': en_US_messages,
  'en-GB': en_GB_messages,

  de: de_DE_messages,
  'de-DE': de_DE_messages,
  'de-AT': de_AT_messages,
  'de-CH': de_CH_messages,

  fr: fr_FR_messages,
  'fr-FR': fr_FR_messages,

  nl: nl_NL_messages,
  'nl-NL': nl_NL_messages,

  sv: sv_SE_messages,
  'sv-SE': sv_SE_messages,

  no: nb_NO_messages,
  'nb-NO': nb_NO_messages,

  fi: fi_FI_messages,
  'fi-FI': fi_FI_messages,
};

export default allAppMessages;

type AppProviderProps = {
  locale: LocaleType;
  country: CountryType;
};
export const AppProviders = ({
  children,
  locale,
  country,
}: PropsWithChildren<AppProviderProps>) => {
  return (
    <LocaleContextProvider
      allAppMessages={allAppMessages}
      locale={locale}
      country={country}
    >
      <ConfigurationPanelContextProvider>
        <ViewportContextProvider>{children}</ViewportContextProvider>
      </ConfigurationPanelContextProvider>
    </LocaleContextProvider>
  );
};

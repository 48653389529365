import clsx from 'clsx';
import { forwardRef,MouseEventHandler, useCallback } from 'react';

import { List, ListItemProps } from '../List';
import styles from './SelectOption.module.scss';

export interface SelectOptionProps extends ListItemProps {
  value: string;
  isSelected?: boolean;
  isHighlighted?: boolean;

  onItemSelect?: (val: SelectOptionProps['value']) => void;
}

export const SelectOption = forwardRef<HTMLLIElement, SelectOptionProps>(
  (
    {
      value,
      className,
      isSelected = false,
      isHighlighted = false,
      onItemSelect,

      children,
      ...props
    },
    ref
  ) => {
    const onClickHandler: MouseEventHandler = useCallback(
      (event) => {
        event.preventDefault();
        onItemSelect && onItemSelect(value);
      },
      [onItemSelect, value]
    );

    return (
      <List.Item
        data-value={value}
        className={clsx(
          styles['select-option'],
          isSelected && [styles['select-option--selected']],
          isHighlighted && [styles['select-option--highlighted']],
          className
        )}
        justifyContent="center"
        onClick={onClickHandler}
        ref={ref}
        {...props}
      >
        {children}
      </List.Item>
    );
  }
);
SelectOption.displayName = 'SelectOption';

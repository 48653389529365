import clsx from 'clsx';
import { forwardRef,HTMLAttributes } from 'react';

import styles from './List.module.scss';
import ListContextProvider from './ListContextProvider';
import { ListItem } from './ListItem';

export interface ListProps extends HTMLAttributes<HTMLUListElement> {
  className?: string;
  dataTest?: string;
  listStyle?: 'disc';
  dense?: boolean;
  hasDividers?: boolean;
}

const ListComponent = forwardRef<HTMLUListElement, ListProps>(
  (
    {
      children,
      className,
      listStyle,
      dataTest = 'list',
      dense = false,
      hasDividers = false,
      ...props
    },
    ref
  ) => {
    return (
      <ListContextProvider dense={dense} hasDividers = {hasDividers}>
        <ul
          ref={ref}
          data-test={dataTest}
          className={clsx(
            styles.list,
            listStyle && styles[listStyle],
            className
          )}
          {...props}
        >
          {children}
        </ul>
      </ListContextProvider>
    );
  }
);

export const List = Object.assign(ListComponent, { Item: ListItem });

List.displayName = 'List';

import { Chevron } from '@checkout-ui/internal-svgs/Chevron';
import clsx from 'clsx';

import { Icon, IconProps } from '../../Icon';
import styles from './AnimatedChevron.module.scss';

interface AnimatedChevronProps extends Partial<IconProps> {
  isOpen: boolean;
}

export function AnimatedChevron({ isOpen, ...props }: AnimatedChevronProps) {
  return (
    <Icon
      {...props}
      className={clsx(styles['chevron'], isOpen && [styles['chevron--open']])}
      icon={Chevron}
    />
  );
}

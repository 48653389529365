import { defaultDateFormat } from './constants/defaultFormats';
import { CustomFormats, Formats } from './types';

const deDefaultFormats: CustomFormats = {
  number: {
    currency: {
      style: 'currency',
      currency: 'EUR', //default value and it should be injected based on shop currency not locale
      minimumFractionDigits: 2,
    },
  },
  ...defaultDateFormat,
};

const enDefaultFormats: CustomFormats = {
  number: {
    currency: {
      style: 'currency',
      currency: 'USD', //default value and it should be injected based on shop currency not locale
      minimumFractionDigits: 2,
    },
  },
  ...defaultDateFormat,
};

const svDefaultFormats: CustomFormats = {
  number: {
    currency: {
      style: 'currency',
      currency: 'SEK', //default value and it should be injected based on shop currency not locale
      minimumFractionDigits: 2,
    },
  },
  ...defaultDateFormat,
};

const chDefaultFormats: CustomFormats = {
  number: {
    currency: {
      style: 'currency',
      currency: 'CHF', //default value and it should be injected based on shop currency not locale
      minimumFractionDigits: 2,
    },
  },
  ...defaultDateFormat,
};

const atDefaultFormats: CustomFormats = {
  number: {
    currency: {
      style: 'currency',
      currency: 'EUR', //default value and it should be injected based on shop currency not locale
      minimumFractionDigits: 2,
    },
  },
  ...defaultDateFormat,
};
const gbDefaultFormats: CustomFormats = {
  number: {
    currency: {
      style: 'currency',
      currency: 'GBP', //default value and it should be injected based on shop currency not locale
      minimumFractionDigits: 2,
    },
  },
  ...defaultDateFormat,
};

const nlDefaultFormats: CustomFormats = {
  number: {
    currency: {
      style: 'currency',
      currency: 'EUR', //default value and it should be injected based on shop currency not locale
      minimumFractionDigits: 2,
    },
  },
  ...defaultDateFormat,
};

const fiDefaultFormats: CustomFormats = {
  number: {
    currency: {
      style: 'currency',
      currency: 'EUR', //default value and it should be injected based on shop currency not locale
      minimumFractionDigits: 2,
    },
  },
  ...defaultDateFormat,
};

const frDefaultFormats: CustomFormats = {
  number: {
    currency: {
      style: 'currency',
      currency: 'EUR', //default value and it should be injected based on shop currency not locale
      minimumFractionDigits: 2,
    },
  },
  ...defaultDateFormat,
};

const noDefaultFormats: CustomFormats = {
  number: {
    currency: {
      style: 'currency',
      currency: 'NOK', //default value and it should be injected based on shop currency not locale
      minimumFractionDigits: 2,
    },
  },
  ...defaultDateFormat,
};

//formats are based on locale , locale can be en or en-US
export const allSupportedFormats: Formats = {
  en: enDefaultFormats,
  'en-US': enDefaultFormats,
  'en-GB': gbDefaultFormats,

  de: deDefaultFormats,
  'de-DE': deDefaultFormats,
  'de-AT': atDefaultFormats,
  'de-CH': chDefaultFormats,

  nl: nlDefaultFormats,
  'nl-NL': nlDefaultFormats,

  fi: fiDefaultFormats,
  'fi-FI': fiDefaultFormats,

  fr: frDefaultFormats,
  'fr-FR': frDefaultFormats,

  sv: svDefaultFormats,
  'sv-SE': svDefaultFormats,

  no: noDefaultFormats,
  'nb-NO': noDefaultFormats,
};

import { Intent } from '@checkout-ui/shared/cross-document-messenger';
import { CountrySelectorOption, LanguageType } from '@checkout-ui/shared-context-locale';
import { CountryType } from '@checkout-ui/shared-domain-entities';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

export type SelectOption = {
  value: string
  text: string
};

export enum IntegrationType {
  KP = 'KP',
  KCO = 'KCO',
}
export type ConfigType = {
  language: LanguageType;
  country: CountryType;
  integration_type: IntegrationType;
  available_countries: string[];
};

export enum State {
  INTEGRATION = 'integration',
  FLOWS = 'flows',
}

export type ConfigurationPanelState = {
  config: ConfigType;
};

export type onChangeEventType = ChangeEvent<
  Omit<HTMLInputElement, 'id'> & {
    id: IntegrationType.KP;
  }
>;

export type ConfigurationChangeHandler =
  & ((val: CountrySelectorOption | null, field: 'language') => void)
  & ((val: SelectOption | null, field: 'country') => void);

export type ConfigurationPanelContextType = {
  updateConfigurations: () => void;
  config: ConfigType;
  url: string;
  handleConfigChange: ConfigurationChangeHandler;
  handleConfigIntegrationChange: (e: onChangeEventType) => void;
  handleConfigFlowChange: (state: State) => void;
  configState: State;
  isViewportEnabled: boolean;
  setIsViewportEnabled: (state: boolean) => void;
  setCurrentScenario: (newScenario: Intent) => void;
  currentScenario: Intent;
  scenarioRestartCount: number;
  setScenarioRestartCount: Dispatch<SetStateAction<number>>;
  isCurrentFlowFinal: boolean;
  setIsCurrentFlowFinal: Dispatch<SetStateAction<boolean>>;
};

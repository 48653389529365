import clsx from 'clsx';
import { forwardRef,HTMLAttributes } from 'react';

import { useListContext } from '../useListContext';
import styles from './ListItem.module.scss';

type DisplayTypes = 'flex' | 'block' | 'none';

type AlignItemsTypes = 'center' | 'flex-start' | 'flex-end';

type JustifyContentTypes = 'center' | 'between';

export interface ListItemProps extends HTMLAttributes<HTMLLIElement> {
  dataTest?: string;
  display?: DisplayTypes;
  alignItems?: AlignItemsTypes;
  justifyContent?: JustifyContentTypes;
}

export const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  (
    {
      children,
      className,

      display = 'flex',
      alignItems = 'flex-start',
      justifyContent = 'between',

      dataTest = 'list-item',

      ...props
    },
    ref
  ): JSX.Element => {
    const { dense ,hasDividers } = useListContext();

    const classes = clsx(
      styles['list-item'],
      display && styles[`display-${display}`],
      dense && styles['list-item__dense'],
      alignItems && styles[`align-${alignItems}`],
      justifyContent && styles[`justify-${justifyContent}`],
      hasDividers && styles['list-item__divider'],
      className)

    return (
      <li
        {...props}
        ref={ref}
        data-test={dataTest}
        className={classes}
      >
        {children}
      </li>
    );
  }
);

ListItem.displayName = 'ListItem';

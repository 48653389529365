import { CustomFormats } from '../types';

type DefaultDateFormat = { date: CustomFormats['date'] };

export const defaultDateFormat: DefaultDateFormat = {
  date: {
    default: {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    },
  },
};

import clsx from 'clsx';
import React from 'react';

import { ButtonBase,ButtonBaseProps } from '../ButtonBase';
import styles from './ButtonPrimary.module.scss';

interface ButtonPrimaryProps extends ButtonBaseProps {
  fontFamily?: 'primary' | 'inherit';
}

export const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  dataTest = 'btn-primary',
  fontFamily = 'primary',
  ...props
}) => {
  return (
    <ButtonBase
      {...props}
      dataTest={dataTest}
      className={clsx(styles['btn--primary'], styles[`font--${fontFamily}`])}
    />
  );
};

export enum ViewportType {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

export type ViewportContextType = {
  viewport: ViewportType;
  handleViewportChange: (value: ViewportType) => void;
  toggleNav: () => void;
  isNavShown: boolean;
};

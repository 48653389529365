import clsx from 'clsx';
import { FocusEventHandler,forwardRef, ReactNode, useState } from 'react';

import styles from '../InputBase.module.scss';
import {
  NonEditableField,
  NonEditableInputElementProps,
} from './NonEditableInputElement';

export interface NonEditableInputBaseProps
  extends NonEditableInputElementProps {
  isActive?: boolean;

  dataTest?: string;
  validationState?: 'success' | 'error';
  inputElementClassName?: string;
  inputAdornments?: {
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
  };
}

export const NonEditableInputBase = forwardRef<
  HTMLButtonElement,
  NonEditableInputBaseProps
>(
  (
    {
      id,
      name,
      value,
      isActive = false,
      dataTest = `${name}-non-editable-input`,
      validationState,
      inputAdornments,

      onChange,
      onFocus,
      onBlur,

      className,
      inputElementClassName,

      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const isError = validationState === 'error';

    const handleFocus: FocusEventHandler<HTMLButtonElement> = (e) => {
      setIsFocused(true);
      onFocus && onFocus(e);
    };

    const handleBlur: FocusEventHandler<HTMLButtonElement> = (e) => {
      setIsFocused(false);
      onBlur && onBlur(e);
    };

    return (
      <div
        data-test={`${dataTest}-wrapper`}
        className={clsx(
          styles['input-wrapper'],
          isFocused && styles['input-wrapper--focused'],
          isActive && styles['input-wrapper--active'],
          isError && styles['input-wrapper--error'],
          className
        )}
      >
        {inputAdornments?.startAdornment && (
          <div
            data-test={`${dataTest}-start-adornment`}
            className={clsx(
              styles['input-wrapper_adornment'],
              styles['input-wrapper_start-adornment']
            )}
          >
            {inputAdornments.startAdornment}
          </div>
        )}

        <NonEditableField
          {...props}
          className={inputElementClassName}
          ref={ref}
          dataTest={`${dataTest}-input-component`}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />

        {inputAdornments?.endAdornment && (
          <div
            data-test={`${dataTest}-end-adornment`}
            className={clsx(
              styles['input-wrapper_adornment'],
              styles['input-wrapper_end-adornment']
            )}
          >
            {inputAdornments.endAdornment}
          </div>
        )}
      </div>
    );
  }
);

NonEditableInputBase.displayName = 'NonEditableInputBase';

import { Address } from '@checkout-ui/shared-domain-entities';
import * as addressFormatter from '@fragaria/address-formatter';

export const formatAddress = (
  address: Address,
  options?: Partial<Parameters<typeof addressFormatter.format>[1]>
) => {
  const formatted = addressFormatter.format(
    {
      houseNumber: address.number,
      road: address.street,
      city: address.city,
      postcode: address.postal_code,
      countryCode: address.country,
    },

    {
      output: 'array',
      abbreviate: false,
      appendCountry: false,
      cleanupPostcode: true,
      countryCode: address.country,
      fallbackCountryCode: address.country,
      ...options,
    }
  );

  return formatted as string[];
};

import { useCallback, useMemo } from 'react';
import {
  FormattedMessage as OriginalFormattedMessage,
  useIntl as useOriginalIntl,
} from 'react-intl';

import {
  FormatMessageParams,
  FormattedMessageProps,
  UseIntlAdapter,
} from './types';
import { useLocaleContext } from './useLocaleContext';
import { getTranslationId } from './utils';

/**
 * An adapter component that adds custom logic for the country specific translation keys.
 */
const FormattedMessageAdapter = ({
  id,
  values,
  ...props
}: FormattedMessageProps) => {
  const { messages } = useOriginalIntl();
  const { country } = useLocaleContext();

  return (
    <OriginalFormattedMessage
      {...props}
      id={getTranslationId(id, country, messages)}
      values={values}
    />
  );
};

/**
 * An extended version of useIntl hook that adds custom logic for
 * the country specific translation keys to formatMessage fn.
 */
const useIntlAdapter = (): UseIntlAdapter => {
  const originalIntl = useOriginalIntl();
  const { messages, formatMessage: originalFormatMessage } = originalIntl;

  const { country } = useLocaleContext();

  // Extend the formatMessage function to accepted country locals
  const formatMessage = useCallback(
    (
      descriptor: FormatMessageParams['0'],
      values?: FormatMessageParams['1'],
      opts?: FormatMessageParams['2']
    ) => {
      const translationId = getTranslationId(descriptor.id, country, messages);
      // @ts-expect-error values type miss-match from inferring the type
      return originalFormatMessage({ id: translationId }, values, opts);
    },
    [country, messages, originalFormatMessage]
  );

  const intl = useMemo(() => {
    // only include used objects from useIntl
    return { formatMessage };
  }, [formatMessage]);

  return intl;
};

export {
  FormattedNumber,
  IntlProvider,
  MissingTranslationError,
} from 'react-intl';
export {
  FormattedMessageAdapter as FormattedMessage,
  useIntlAdapter as useIntl,
};

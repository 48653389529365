import clsx from 'clsx';
import { ButtonHTMLAttributes, FC,ReactNode } from 'react';

import { Spinner, SpinnerVariant } from '../../Spinner';
import styles from './ButtonBase.module.scss';

export interface ButtonBaseProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  isLoading?: boolean;
  children: ReactNode;
  dataTest?: string;
  spinnerVariant?: keyof typeof SpinnerVariant;
}

export const ButtonBase: FC<ButtonBaseProps> = ({
  children,
  isLoading,
  className,
  spinnerVariant = 'primary',
  dataTest = 'btn-base',
  ...otherProps
}) => {
  return (
    <button
      {...otherProps}
      className={clsx(styles.btn, className)}
      data-test={dataTest}
    >
      {isLoading ? <Spinner variant={spinnerVariant} /> : children}
    </button>
  );
};

import { IconType } from '.';

export const Error: IconType = (props) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9755 2.16665C7.4175 1.35599 8.58151 1.35599 9.02351 2.16665L15.2342 13.5527C15.3311 13.7304 15.3801 13.9303 15.3764 14.1327C15.3727 14.3351 15.3164 14.533 15.2131 14.7071C15.1097 14.8811 14.9628 15.0253 14.7869 15.1254C14.6109 15.2255 14.4119 15.2781 14.2095 15.278H1.79017C1.58774 15.2781 1.38877 15.2255 1.21281 15.1254C1.03686 15.0253 0.889983 14.8811 0.786613 14.7071C0.683244 14.533 0.626943 14.3351 0.623242 14.1327C0.619541 13.9303 0.668568 13.7304 0.765505 13.5527L6.9755 2.16665ZM8.1455 2.64599C8.13112 2.61986 8.10998 2.59807 8.0843 2.58289C8.05862 2.56771 8.02934 2.55971 7.99951 2.55971C7.96967 2.55971 7.94039 2.56771 7.91471 2.58289C7.88903 2.59807 7.86789 2.61986 7.85351 2.64599L1.64217 14.0313C1.62827 14.0567 1.62122 14.0853 1.62174 14.1143C1.62226 14.1433 1.63032 14.1716 1.64513 14.1965C1.65993 14.2214 1.68097 14.242 1.70618 14.2563C1.73138 14.2706 1.75987 14.2781 1.78884 14.278H14.2088C14.2378 14.2781 14.2663 14.2706 14.2915 14.2563C14.3167 14.242 14.3377 14.2214 14.3525 14.1965C14.3674 14.1716 14.3754 14.1433 14.3759 14.1143C14.3765 14.0853 14.3694 14.0567 14.3555 14.0313L8.1455 2.64599Z"
        fill="currentColor"
      />
      <path
        d="M8.00065 6.63336C7.86804 6.63336 7.74087 6.68604 7.6471 6.77981C7.55333 6.87358 7.50065 7.00075 7.50065 7.13336V10.3547C7.50065 10.4873 7.55333 10.6145 7.6471 10.7082C7.74087 10.802 7.86804 10.8547 8.00065 10.8547C8.13326 10.8547 8.26044 10.802 8.3542 10.7082C8.44797 10.6145 8.50065 10.4873 8.50065 10.3547V7.13336C8.50065 7.00075 8.44797 6.87358 8.3542 6.77981C8.26044 6.68604 8.13326 6.63336 8.00065 6.63336ZM8.00065 11.4334C7.63198 11.4334 7.33398 11.7374 7.33398 12.1067C7.33398 12.484 7.65265 12.7667 8.00065 12.7667C8.36932 12.7667 8.66732 12.4634 8.66732 12.0934C8.66626 11.9175 8.59547 11.7492 8.47048 11.6255C8.3455 11.5017 8.17653 11.4327 8.00065 11.4334Z"
        fill="currentColor"
      />
    </svg>
  );
};

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Link } from '@checkout-ui/design-system/Link'; //FIXME: make DS buildable
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Text } from '@checkout-ui/design-system/Text'; //FIXME: make DS buildable
import { FC, ReactNode } from 'react';

import { ResolvedIntlConfig } from '../types';
import { parseAnchorLink } from '../utils';

export const UrlWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return <>{children}</>;
};

export const defaultRichTextElements: ResolvedIntlConfig['defaultRichTextElements'] =
  {
    br: () => <br />,
    b: (chunks) => <Text fontWeight="semi-bold">{chunks}</Text>,
    strong: (chunks) => <Text fontWeight="semi-bold">{chunks}</Text>,
    /**
     * enables embedding the url to the link in the same translation key
     * @example
     * <link><to>https://billie.io</to>Billie website</to></link>
     */
    to: (chunks) => <UrlWrapper>{chunks}</UrlWrapper>,

    /**
     * default link as anchor tag and aware of url syntax
     * @Example
     * <link><to>https://billie.io</to>Billie website</to></link>
     *
     * @Note
     * you can always override the link value if you need to use a button with a click handler
     * of a special anchor
     */
    link: (chunks) => {
      const { href, children } = parseAnchorLink(chunks);

      return (
        <Link tag="a" href={href} target="_blank" rel="help">
          {children}
        </Link>
      );
    },

    highlighted: (chunk) => <Text color="accent-1">{chunk}</Text>,
    bolded: (chunk) => (
      <Text fontWeight="semi-bold" color="neutral-900">
        {chunk}
      </Text>
    ),
  };

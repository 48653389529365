const Logo = (props: { width?: string; height?: string }) => (
  <svg
    width="60"
    height="24"
    viewBox="0 0 60 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.096 23.954H0V7.36869C0 5.34334 0.84682 3.38393 2.32217 1.99727C3.8201 0.589884 5.7772 -0.11475 7.83403 0.015249C11.6297 0.254523 14.7177 3.47436 14.7177 7.19348C14.7177 8.37666 14.5521 9.59375 14.3282 10.5603C16.2194 11.8961 17.4558 14.0985 17.4558 16.5855C17.4558 20.6494 14.1532 23.954 10.096 23.954ZM6.78397 20.8792H10.096C12.4614 20.8792 14.3847 18.9518 14.3847 16.5855C14.3847 15.406 13.9086 14.3378 13.137 13.5597C11.6767 16.3405 9.5973 18.7408 6.78397 20.8792ZM3.06925 12.2898V19.7281C6.45654 17.5671 8.78059 15.1856 10.3312 12.2974C10.2522 12.2936 10.1731 12.2917 10.0941 12.2917H3.07114L3.06925 12.2898ZM3.06925 9.21506H10.096C10.557 9.21506 11.0068 9.25839 11.4434 9.33941C11.5563 8.71579 11.6466 7.9584 11.6466 7.19348C11.6466 5.06638 9.84946 3.2219 7.6402 3.08437C6.43772 3.00712 5.29545 3.41784 4.42417 4.23929C3.551 5.06073 3.06925 6.17232 3.06925 7.37058V9.21694V9.21506Z"
      fill="#1E1E1E"
    />
    <path
      d="M45.1833 5.958C45.5465 6.32162 45.729 6.7719 45.729 7.30697C45.729 7.84204 45.5465 8.28668 45.1833 8.64277C44.8201 8.99885 44.3459 9.17783 43.7644 9.17783C43.1829 9.17783 42.7331 9.00073 42.3699 8.64277C42.0068 8.28668 41.8242 7.84204 41.8242 7.30697C41.8242 6.7719 42.0068 6.3235 42.3699 5.958C42.7331 5.59437 43.198 5.41162 43.7644 5.41162C44.3308 5.41162 44.8201 5.59437 45.1833 5.958Z"
      fill="#1E1E1E"
    />
    <path
      d="M24.0334 5.958C24.3966 6.32162 24.5791 6.7719 24.5791 7.30697C24.5791 7.84204 24.3966 8.28668 24.0334 8.64277C23.6702 8.99885 23.196 9.17783 22.6145 9.17783C22.033 9.17783 21.5832 9.00073 21.22 8.64277C20.8569 8.28668 20.6743 7.84204 20.6743 7.30697C20.6743 6.7719 20.8569 6.3235 21.22 5.958C21.5832 5.59437 22.048 5.41162 22.6145 5.41162C23.1809 5.41162 23.6702 5.59437 24.0334 5.958Z"
      fill="#1E1E1E"
    />
    <path
      d="M45.3286 10.2926V23.6354H42.2236V10.8013L45.3286 10.2926Z"
      fill="#1E1E1E"
    />
    <path
      d="M24.1695 10.2928V23.6357H21.0645V10.8015L24.1695 10.2928Z"
      fill="#1E1E1E"
    />
    <path
      d="M59.529 18.1653H51.4729C51.5708 19.4125 51.8926 20.3037 52.444 20.8369C52.9934 21.3719 53.7217 21.6376 54.6269 21.6376C55.1933 21.6376 55.7259 21.5453 56.2283 21.3587C56.7289 21.1722 57.2633 20.8859 57.8297 20.4959L59.115 22.2687C57.6924 23.418 56.106 23.9927 54.3596 23.9927C52.4026 23.9927 50.8933 23.3935 49.8339 22.1953C48.7744 20.997 48.2456 19.3617 48.2456 17.2892C48.2456 15.9779 48.4714 14.7985 48.9249 13.7547C49.3785 12.711 50.0333 11.8933 50.8896 11.3017C51.7477 10.7101 52.7657 10.4143 53.9475 10.4143C55.7428 10.4143 57.1335 10.9852 58.1214 12.1269C59.1075 13.2686 59.6024 14.8607 59.6024 16.9011C59.6024 17.0631 59.5779 17.4833 59.529 18.1634V18.1653ZM56.52 16.0043C56.52 13.7528 55.6863 12.6281 54.0209 12.6281C53.2607 12.6281 52.666 12.9069 52.237 13.4665C51.8079 14.026 51.5538 14.921 51.4729 16.1512H56.52V16.0062V16.0043Z"
      fill="#1E1E1E"
    />
    <path
      d="M38.0501 5.29517V20.5955C38.0501 20.946 38.2985 21.2305 38.6053 21.2305H39.0964V24H37.5891C36.1325 24 34.9526 22.6491 34.9526 20.9799V5.67763L38.052 5.29517H38.0501Z"
      fill="#1E1E1E"
    />
    <path
      d="M30.8831 5.29517V20.5955C30.8831 20.946 31.1315 21.2305 31.4383 21.2305H31.9294V24H30.4221C28.9655 24 27.7856 22.6491 27.7856 20.9799V5.67763L30.885 5.29517H30.8831Z"
      fill="#1E1E1E"
    />
  </svg>
);
export default Logo;

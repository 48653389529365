import { IconType } from './types';

export const Mobile: IconType = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.125 0.75H7.875C6.21815 0.75 4.875 2.09315 4.875 3.75V20.25C4.875 21.9069 6.21815 23.25 7.875 23.25H16.125C17.7819 23.25 19.125 21.9069 19.125 20.25V3.75C19.125 2.09315 17.7819 0.75 16.125 0.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3.7749C11.8619 3.7749 11.75 3.66297 11.75 3.5249C11.75 3.38683 11.8619 3.2749 12 3.2749"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M12 3.7749C12.1381 3.7749 12.25 3.66297 12.25 3.5249C12.25 3.38683 12.1381 3.2749 12 3.2749"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

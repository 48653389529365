import '../styles.scss';

import { BodyText } from '@checkout-ui/design-system/BodyText';
import { LocaleType } from '@checkout-ui/shared-context-locale';
import { CountryType } from '@checkout-ui/shared-domain-entities';
import { logger } from '@checkout-ui/shared-logger';
import {
  getNewrelicAppIdForEnv,
  initTracing,
} from '@checkout-ui/shared-tracing';
import * as Sentry from '@sentry/nextjs';
import { AppProps } from 'next/app';

import { AppProviders } from '../providers';

logger.setConfig({ name: 'DemoShop', color: 'darkBlue' });

//TODO: move to build time env variables
const newRelicApplicationIDForProd = '1835013967';
const newRelicApplicationIDForPaellaSandbox = '1835013965';

initTracing(
  Sentry,
  getNewrelicAppIdForEnv({
    prodId: newRelicApplicationIDForProd,
    paellaSandboxId: newRelicApplicationIDForPaellaSandbox,
  })
);

function DemoShop({ Component, pageProps, router }: AppProps) {
  const locale = router.locale as LocaleType;
  const country = router.query?.country
    ?.toString()
    .toUpperCase() as CountryType;

  if (!country) {
    logger.error('invalid country');
    return <BodyText>Something went wrong (invalid country)</BodyText>;
  }
  return (
    <AppProviders locale={locale} country={country}>
      <Component {...pageProps} />
    </AppProviders>
  );
}

export default DemoShop;

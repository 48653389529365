import { CountryType, CurrencyType } from '@checkout-ui/shared-domain-entities';
import { logger } from '@checkout-ui/shared-logger';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import {
  DEFAULT_COUNTRY_LANGUAGE_LOCALE,
  DEFAULT_COUNTRY_SUPPORTED_LANGUAGES_FOR_UI,
} from './constants/defaultLocaleValue';
import { defaultRichTextElements } from './constants/defaultRichTextElements';
import {
  HPP_STRIPE_COUNTRY_LANGUAGE_LOCALE,
  HPP_STRIPE_COUNTRY_SUPPORTED_LANGUAGES_FOR_UI,
} from './constants/hppLocaleValue';
import { getMessages } from './getMessages';
import { ChangeLocaleValuesType, LocaleContext } from './LocaleContext';
import { IntlProvider } from './ReactIntlAdapter';
import { transformMessagesLocaleKeysToLowercase } from './transformMessagesLocaleKeysToLowercase';
import { IntlConfig, LocaleType, Messages } from './types';
import {
  getDefaultLocaleValues,
  getFormat,
  injectFormatCurrency,
} from './utils';

type LocaleContextProviderProps = PropsWithChildren<{
  onChangeLocaleValues?: () => void;
  allAppMessages: Messages;
  locale?: LocaleType;
  country?: CountryType;
  currency?: CurrencyType;
  showCoreLanguages?: boolean;
}> &
  Partial<IntlConfig>;
export const LocaleContextProvider = ({
  children,

  onChangeLocaleValues,

  allAppMessages,
  locale: initialLocale,
  country: initialCountry,
  currency: initialCurrency,
  showCoreLanguages = false,

  ...intlConfigProps
}: LocaleContextProviderProps) => {
  const [{ country, currency, language, locale }, setLocaleValues] = useState(
    () => getDefaultLocaleValues(initialLocale, initialCountry, initialCurrency)
  );

  const countryLanguageLocale = showCoreLanguages
    ? HPP_STRIPE_COUNTRY_LANGUAGE_LOCALE
    : DEFAULT_COUNTRY_LANGUAGE_LOCALE;
  const countrySupportedLanguagesForUi = showCoreLanguages
    ? HPP_STRIPE_COUNTRY_SUPPORTED_LANGUAGES_FOR_UI
    : DEFAULT_COUNTRY_SUPPORTED_LANGUAGES_FOR_UI;

  const changeLocaleValues: ChangeLocaleValuesType = useCallback(
    (values) => {
      setLocaleValues((prevValues) => {
        const merged = { ...prevValues, ...values };

        logger.debug('changeLocaleValues :', {
          old: prevValues,
          new: values,
          merged,
        });

        return merged;
      });

      if (onChangeLocaleValues) {
        onChangeLocaleValues();
      }
    },
    [onChangeLocaleValues]
  );

  // TODO: the approach of using allAppMessages is not ideal
  // we will be loading hundreds of unused strings into the client
  // we should tree-shake unused strings and only include the localized Messages in the bundle
  // and lazy load other messages on demand (language change on client side)
  const messages = useMemo(
    () => transformMessagesLocaleKeysToLowercase(allAppMessages),
    [allAppMessages]
  );
  const localizedMessages = getMessages(messages, language.toLowerCase());
  const localizedFormats = injectFormatCurrency(getFormat(locale), currency);

  //TODO: change the return value to an array [values,updaterFunction] to match other contexts and react state conventions
  const value = useMemo(
    () => ({
      country,
      currency,
      language,
      locale,
      changeLocaleValues,
      countryLanguageLocale,
      countrySupportedLanguagesForUi,
    }),
    [
      changeLocaleValues,
      country,
      countryLanguageLocale,
      countrySupportedLanguagesForUi,
      currency,
      language,
      locale,
    ]
  );

  return (
    <LocaleContext.Provider value={value}>
      <IntlProvider
        locale={locale}
        messages={localizedMessages}
        formats={localizedFormats}
        defaultRichTextElements={defaultRichTextElements}
        {...intlConfigProps}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

import { defaultLocaleValue } from './constants/defaultLocaleValue';
import { Translations } from './types';

export const getMessages = (messages: Record<string, Translations>, language: string): Translations => {
  // we should allow the user to switch language independently from their locale
  // eg: have en translations for de-DE locale
  return (
    messages[language] ||
    messages[language.split('-')[0]] || // to support unconventional languages eg en-de
    messages[defaultLocaleValue.language.toLowerCase()] // failsafe
  );
};

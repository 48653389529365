import { CountryType, CurrencyType } from '@checkout-ui/shared-domain-entities';
import { createContext } from 'react';

import { LanguageType, LocaleType } from './types';

export type LocaleValuesType = {
  country: CountryType;
  currency: CurrencyType;
  locale: LocaleType;
  language: LanguageType;
};

export type ChangeLocaleValuesType = (
  values: Partial<LocaleValuesType>
) => void;

type LocaleContextType =
  | LocaleValuesType & {
      changeLocaleValues: ChangeLocaleValuesType;
    };

export const LocaleContext = createContext<LocaleContextType | undefined>(
  undefined
);
LocaleContext.displayName = 'LocaleContext';

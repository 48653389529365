import { IconType } from './types';

export const Tablet: IconType = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.74414 18.75H20.2441"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7471 0.75H5.24707C4.41864 0.75 3.74707 1.42157 3.74707 2.25V21.75C3.74707 22.5784 4.41864 23.25 5.24707 23.25H18.7471C19.5755 23.25 20.2471 22.5784 20.2471 21.75V2.25C20.2471 1.42157 19.5755 0.75 18.7471 0.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { logger } from '@checkout-ui/shared-logger';
import { useCallback, useMemo } from 'react';

import { IdentifyParams, MetaData, TrackEventType } from './types';

const metaDataVars: MetaData = {
  session_id: undefined,
  integration_type: undefined,
  locale: undefined,
  country: undefined,
  channel: undefined,
  merchant_legal_name: undefined,
};

function persistInternalValues({
  session_id,
  integration_type,
  locale,
  country,
  channel,
  merchant_legal_name,
}: MetaData) {
  if (session_id) {
    metaDataVars.session_id = session_id;
  }

  if (integration_type) {
    metaDataVars.integration_type = integration_type;
  }

  if (locale) {
    metaDataVars.locale = locale;
  }

  if (country) {
    metaDataVars.country = country;
  }

  if (channel) {
    metaDataVars.channel = channel;
  }

  if (merchant_legal_name) {
    metaDataVars.merchant_legal_name = merchant_legal_name;
  }
}

export function useAnalytics() {
  const setMetaData = useCallback(
    ({
      locale,
      country,
      integration_type,
      session_id,
      channel,
      merchant_legal_name,
    }: MetaData) => {
      if (!window) return;
      const { analytics } = window;

      const metadata = {
        session_id,
        integration_type,
        locale,
        country,
        channel,
        merchant_legal_name,
      };

      persistInternalValues(metadata);

      if (!analytics) {
        return logger.info('set metadata:', metadata);
      }

      analytics.identify(metadata);
    },
    []
  );

  const identify = useCallback(
    (...args: IdentifyParams) => {
      if (!window) return;

      const analytics = window.analytics;
      const [userId, traits, ...rest] = args;

      setMetaData({
        session_id: userId,
      });

      const metaData = {
        session_id: metaDataVars.session_id,
        locale: metaDataVars.locale,
        country: metaDataVars.country,
        integration_type: metaDataVars.integration_type,
        channel: metaDataVars.channel,
        merchant_legal_name: metaDataVars.merchant_legal_name,
      };

      if (!analytics) {
        return logger.info(
          'analytics identify:',
          { ...traits, ...metaData },
          ...rest
        );
      }

      analytics.identify({ ...traits, ...metaData }, ...rest);
    },
    [setMetaData]
  );

  const trackEvent: TrackEventType = useCallback((...args) => {
    if (!window) return;
    const analytics = window.analytics;
    const [eventName, properties, ...rest] = args;

    const metaData = {
      session_id: metaDataVars.session_id,
      locale: metaDataVars.locale,
      country: metaDataVars.country,
      integration_type: metaDataVars.integration_type,
      channel: metaDataVars.channel,
      merchant_legal_name: metaDataVars.merchant_legal_name,
    };

    if (!analytics) {
      return logger.info(
        'analytics trackEvent:',
        eventName,
        { ...properties, ...metaData },
        ...rest
      );
    }

    return analytics.track(
      eventName,
      {
        ...properties,
        ...metaData,
      },
      ...rest
    );
  }, []);
  return useMemo(
    () => ({ trackEvent, identify, setMetaData }),
    [trackEvent, identify, setMetaData]
  );
}

export default useAnalytics;

import clsx from 'clsx';
import {
  Children,
  ElementType,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
} from 'react';

import { Caption } from '../Caption';
import { Dropdown } from '../Dropdown';
import { List } from '../List';
import { SelectOption } from './SelectOption';
import styles from './Selectv2.module.scss';
import { Trigger } from './Trigger';

export interface Selectv2Props
  extends Omit<HTMLAttributes<HTMLUListElement>, 'placeholder'> {
  isOpen: boolean;
  value: string | ReactNode;
  placeholder: string | ReactNode;
  label: string | ReactNode;
  dataTest?: string;
  className?: string;

  trigger?: ElementType; // any element allowing flexibility but providing no type safety

  menuProps?: HTMLAttributes<HTMLUListElement>;
  triggerProps?: HTMLAttributes<HTMLButtonElement>;
  labelProps?: HTMLAttributes<HTMLLabelElement>;

  validationState?: 'success' | 'error';
  helperText?: string | ReactNode;
}

export const InvalidSelectOptionError =
  'children of select can only be select options';

const ValidateChildrenType = (children: ReactNode) =>
  Children.forEach(children, (child) => {
    if (!(isValidElement(child) && child.type === SelectOption)) {
      throw new Error(InvalidSelectOptionError);
    }
  });

const Selectv2Component = forwardRef<
  HTMLUListElement,
  PropsWithChildren<Selectv2Props>
>(
  (
    {
      isOpen,
      value,
      placeholder,
      label,
      dataTest = 'ds-selectv2',
      className,
      children,

      trigger: CustomTrigger,

      menuProps,
      triggerProps,
      labelProps,

      validationState,
      helperText,

      ...otherListProps
    },
    ref
  ) => {
    const containerRef = useRef(null);

    useEffect(() => {
      if (process.env.NODE_ENV === 'development') {
        ValidateChildrenType(children);
      }
    }, [children]);

    const SelectTrigger = () => {
      const isError = validationState === 'error';
      const shouldShowHelperText = helperText && !isOpen;

      const sharedProps = {
        value,
        label,
        placeholder,
        isOpen,
        labelProps,
        validationState,
        ...triggerProps,
      };

      return (
        <div
          className={styles['container']}
          data-test={dataTest + '__container'}
        >
          {CustomTrigger ? (
            <CustomTrigger
              dataTest={dataTest + '__custom-trigger'}
              {...sharedProps}
              helperText={helperText}
            />
          ) : (
            <>
              <Trigger dataTest={dataTest + '__trigger'} {...sharedProps} />

              {shouldShowHelperText && (
                <Caption
                  data-test={`${dataTest}__helper-text`}
                  className={clsx(
                    styles['helper-text'],
                    isError && styles['helper-text--error']
                  )}
                >
                  {helperText}
                </Caption>
              )}
            </>
          )}
        </div>
      );
    };

    return (
      <div ref={containerRef} data-test={dataTest} className={className}>
        <Dropdown
          isExpanded={isOpen}
          trigger={<SelectTrigger />}
          dataTest={dataTest + '-dropdown'}
        >
          <List
            ref={ref}
            dataTest={dataTest + '-list'}
            {...otherListProps}
            {...menuProps}
          >
            {children}
          </List>
        </Dropdown>
      </div>
    );
  }
);

export const Selectv2 = Object.assign(Selectv2Component, {
  Option: SelectOption,
});

Selectv2.displayName = 'Selectv2';

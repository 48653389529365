import { CountryType, CurrencyType } from '@checkout-ui/shared-domain-entities';
import { logger } from '@checkout-ui/shared-logger';
import merge from 'lodash.merge';
import { isValidElement, ReactNode } from 'react';

import { defaultLocaleValue } from './constants/defaultLocaleValue';
import { UrlWrapper } from './constants/defaultRichTextElements';
import { LocaleValuesType } from './LocaleContext';
import { allSupportedFormats } from './supportedFormats';
import {
  CustomFormats,
  FormattedMessageProps,
  LocaleType,
  ReactIntlMessages,
} from './types';

export const getFormat = (locale: LocaleType) => {
  return (
    allSupportedFormats[locale] ||
    allSupportedFormats[locale.split('-')[1]?.toLowerCase() as LocaleType] || //to support unconventional locales eg en-de
    allSupportedFormats[defaultLocaleValue.locale] // failsafe
  );
};

export const injectFormatCurrency = (
  format: CustomFormats,
  currency: CurrencyType
) => {
  return merge(format, { number: { currency: { currency } } });
};

export const getDefaultLocaleValues = (
  initialLocale?: LocaleType,
  initialCountry?: CountryType,
  initialCurrency?: CurrencyType
): LocaleValuesType => {
  return {
    ...defaultLocaleValue,
    ...(initialLocale && {
      locale: initialLocale,
      language: initialLocale,
    }),
    ...(initialCountry && {
      country: initialCountry,
    }),
    ...(initialCurrency && {
      currency: initialCurrency,
    }),
  };
};

export const failSafeUrl = '#';
export const parseAnchorLink = (chunks: ReactNode[]) => {
  let href = failSafeUrl;

  try {
    chunks.forEach((chunk) => {
      if (
        isValidElement(chunk) &&
        chunk.type === UrlWrapper &&
        chunk.props.children
      ) {
        const childString = (
          chunk.props.children as string | string[]
        ).toString();

        if (childString) {
          href = childString;
        }
      }
    });

    const children = chunks.filter((chunk) => !isValidElement(chunk));

    return { href, children };
  } catch (error) {
    logger.error(error);

    return { href, children: chunks };
  }
};

export const getTranslationId = (
  id: FormattedMessageProps['id'],
  country: CountryType,
  messages: ReactIntlMessages
) => {
  if (!id) {
    return;
  }
  const originalTranslationIdArray = id.split('.');
  const keyHasCountry = originalTranslationIdArray.indexOf(country) === 1;
  const isKeyExist = Boolean(messages[id]);
  const isCountrySpecificKeyAvailable = keyHasCountry && isKeyExist;

  if (isCountrySpecificKeyAvailable) {
    return id;
  }

  if (keyHasCountry && !isKeyExist) {
    const newTranslationArray = [...originalTranslationIdArray];
    newTranslationArray.splice(1, 1);
    const genericId = newTranslationArray.join('.');
    return genericId;
  }

  if (!keyHasCountry) {
    const newTranslationIdArray = [...originalTranslationIdArray];
    newTranslationIdArray.splice(1, 0, country);
    const idWithCountry = newTranslationIdArray.join('.');
    const isKeyWithCountryExist = Boolean(messages[idWithCountry]);

    if (isKeyWithCountryExist) {
      return idWithCountry;
    }
  }

  return id;
};

import clsx from 'clsx';
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  FC,
  MouseEventHandler,
} from 'react';

import { Icon } from '../Icon';
import styles from './Link.module.scss';

type BaseProps = {
  underline?: boolean;
  className?: string;
  dataTest?: string;
  children: React.ReactNode;
};

type LinkAsAnchor = BaseProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseProps> & {
    tag?: 'a';
    href: string;
  };

type LinkAsButton = BaseProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
    tag: 'button';
    onClick: MouseEventHandler<HTMLButtonElement>;
  };

export type LinkProps = LinkAsAnchor | LinkAsButton;

type LinkComponentSystem = FC<LinkProps> & {
  Icon: typeof Icon;
};

export const Link: LinkComponentSystem = ({
  dataTest = 'link',
  tag: Element = 'a' as React.ElementType,
  underline = true,

  children,
  className,
  ...props
}): JSX.Element => (
  <Element
    className={clsx(
      styles['link'],
      underline && styles['link--underline'],
      className
    )}
    data-test={dataTest}
    {...props}
  >
    {children}
  </Element>
);

Link.Icon = ({ className, ...props}) => (
  <Icon
    {...props}
    className={clsx(
      styles.icon,
      className,
    )}
  />
)

export default Link;

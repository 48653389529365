import { IconType } from '@checkout-ui/internal-svgs';
import clsx from 'clsx';
import { SVGProps } from 'react';

import styles from './Icon.module.scss';

export enum IconSize {
  sm = 16,
  md = 24,
  lg = 42,
}

export interface IconProps extends SVGProps<SVGSVGElement> {
  icon: IconType;
  size?: keyof typeof IconSize;
}

export function Icon({
  icon: IconComponent,
  size = 'md',
  ...svgProps
}: IconProps) {
  const testId = IconComponent.displayName
    ? `${IconComponent.displayName.toLocaleLowerCase()}-icon`
    : 'icon';

  return (
    <IconComponent
      {...svgProps}
      data-test={testId}
      width={IconSize[size]}
      height={IconSize[size]}
      className={clsx(styles['icon'], svgProps.className)}
    />
  );
}

import clsx from 'clsx';
import { ChangeEvent, FocusEvent, InputHTMLAttributes } from 'react';

import styles from './Radio.module.scss';
import { RadioIcon } from './RadioIcon';
import { RadioIconUnselected } from './RadioIconUnselected';

type FocusEventHandler = (event: FocusEvent<HTMLInputElement>) => void;
type ChangeEventHandler<T> = (
  event: ChangeEvent<Omit<HTMLInputElement, 'id'> & { id: T }>
) => void;

export interface RadioProps<T extends string = string>
  extends InputHTMLAttributes<Omit<HTMLInputElement, 'id'>> {
  checked: boolean;
  id: T;
  onChange: ChangeEventHandler<T>;

  name?: string;
  className?: string;
  dataTest?: string;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  value?: string;
}

export function Radio<T extends string = string>({
  id,
  className,
  name = 'radio',
  checked = false,
  dataTest = `ds-${name}`,
  onBlur,
  onFocus,
  onChange,
  value,
  ...props
}: RadioProps<T>) {
  return (
    <div className={clsx(styles.radio, className)} data-test={dataTest}>
      <div className={styles.radio__wrapper}>
        <input
          className={styles.radio__input}
          type="radio"
          id={id}
          name={name}
          checked={checked}
          data-test={`${dataTest}-input`}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          {...props}
        />
        <label
          role="presentation"
          className={clsx(styles.radio__label, {
            [styles['radio__label--checked']]: checked,
          })}
          data-test={`${dataTest}-label`}
          htmlFor={id}
        >
          {checked ? <RadioIcon /> : <RadioIconUnselected />}
        </label>
      </div>
    </div>
  );
}

export default Radio;

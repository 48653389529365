import {
  Config,
  CrossDocumentMessengerProvider,
  Intent,
  TOPICS,
} from '@checkout-ui/shared/cross-document-messenger';
import { useLocaleContext } from '@checkout-ui/shared-context-locale';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  ConfigType,
  ConfigurationChangeHandler,
  ConfigurationPanelContextType,
  IntegrationType,
  onChangeEventType,
  State
} from './types';

export const ConfigurationPanelContext = createContext<
  ConfigurationPanelContextType | undefined
>(undefined);

ConfigurationPanelContext.displayName = 'ConfigurationPanelContext';

export const ConfigurationPanelContextProvider = ({
  children,
}: PropsWithChildren<Record<never, never>>) => {
  const { country, language, changeLocaleValues } = useLocaleContext();
  const { push, query, pathname } = useRouter();
  const { publicRuntimeConfig } = getConfig();

  const [config, setConfig] = useState<ConfigType>({
    language: language,
    country: country,
    integration_type: IntegrationType.KP,
    available_countries: publicRuntimeConfig?.availableCountries || [],
  });

  const [currentScenario, setCurrentScenario] =
    useState<Intent>('confirmation');
  const [configState, setConfigState] = useState<State>(State.INTEGRATION);
  const [isViewportEnabled, setIsViewportEnabled] = useState<boolean>(false);
  const [scenarioRestartCount, setScenarioRestartCount] = useState(0);
  const [isCurrentFlowFinal, setIsCurrentFlowFinal] = useState(false);

  const configUrl = useCallback(
    () =>
      `/${config.language}/${config.country}/${config.integration_type}?env=demo`,
    [config.language, config.country, config.integration_type]
  );

  const [url, setUrl] = useState(configUrl());

  const crossDocumentMessengerConfig: Config = useMemo(() => {
    return {
      persistedTopics: [TOPICS.intent],
    };
  }, []);

  const handleConfigChange = useCallback<ConfigurationChangeHandler>(
    (selectedItem, field) => {
      return setConfig((prevValues) => ({
        ...prevValues,
        [field]: selectedItem ? selectedItem.value : null,
      }));
    },
    [setConfig]
  );
  const handleConfigIntegrationChange = useCallback(
    (e: onChangeEventType) => {
      return setConfig((prevValues) => ({
        ...prevValues,
        [e.target.name]: e.target.value,
      }));
    },
    [setConfig]
  );
  const handleConfigFlowChange = useCallback((state: State) => {
    return setConfigState(state);
  }, []);

  const updateConfigurations = useCallback(() => {
    const countryCodePrettified = config.country.toLowerCase()

    const nextQuery = {
      ...query,
      country: countryCodePrettified,
    };

    const nextPath = `/${config.language}/${countryCodePrettified}`;

    push({ pathname, query: nextQuery }, nextPath, {
      locale: config.language,
      shallow: true,
    });
    setIsViewportEnabled(true);
    changeLocaleValues({ locale: config.language, language: config.language });
    // FIXME: temporary solution to work around current scope & react context limitations
    //  we should switch to using the first value in the list
    setCurrentScenario('confirmation');
    setUrl(configUrl());
    return;
  }, [
    pathname,
    query,
    setIsViewportEnabled,
    config.country,
    config.language,
    changeLocaleValues,
    push,
    configUrl,
  ]);

  const configValues: ConfigurationPanelContextType = useMemo(
    () => ({
      updateConfigurations,
      config,
      url,
      handleConfigChange,
      handleConfigIntegrationChange,
      configState,
      isViewportEnabled,
      handleConfigFlowChange,
      setIsViewportEnabled,
      currentScenario,
      setCurrentScenario,
      configUrl,
      setConfig,
      scenarioRestartCount,
      setScenarioRestartCount,
      isCurrentFlowFinal,
      setIsCurrentFlowFinal,
    }),
    [
      updateConfigurations,
      config,
      url,
      handleConfigChange,
      handleConfigIntegrationChange,
      configState,
      isViewportEnabled,
      handleConfigFlowChange,
      currentScenario,
      configUrl,
      scenarioRestartCount,
      isCurrentFlowFinal,
    ]
  );
  return (
    <ConfigurationPanelContext.Provider value={configValues}>
      <CrossDocumentMessengerProvider config={crossDocumentMessengerConfig}>
        {children}
      </CrossDocumentMessengerProvider>
    </ConfigurationPanelContext.Provider>
  );
};

export const useConfigurationPanel = () => {
  const context = useContext(ConfigurationPanelContext);

  if (context === undefined) {
    throw new Error(
      `useConfigurationPanel must be used within a ConfigurationPanelContextProvider`
    );
  }

  return context;
};

export default ConfigurationPanelContextProvider;

import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import styles from './Overlay.module.scss';

interface DropdownOverlayProps {
  visible?: boolean;
  dataTest?: string;
  className?: string;
}

export function Overlay({
  children,
  visible = false,
  dataTest = 'ds-dropdown-overlay',
  className,
}: PropsWithChildren<DropdownOverlayProps>) {
  return (
    <div
      data-test={dataTest}
      className={clsx(styles['overlay'], className, {
        [styles['overlay--hidden']]: !visible,
      })}
      role="dialog"
    >
      {children}
    </div>
  );
}

import {
  CountryType,
  SupportedLanguage,
} from '@checkout-ui/shared-domain-entities';

import { DEFAULT_COUNTRY_LANGUAGE_LOCALE } from './constants/defaultLocaleValue';
import { HPP_STRIPE_COUNTRY_LANGUAGE_LOCALE } from './constants/hppLocaleValue';

export const isSupportedLanguage = (
  countryCode: CountryType,
  languageCode: string,
  showCoreLanguages: boolean
): languageCode is SupportedLanguage => {
  const countryConfig = showCoreLanguages
    ? HPP_STRIPE_COUNTRY_LANGUAGE_LOCALE[countryCode]
    : DEFAULT_COUNTRY_LANGUAGE_LOCALE[countryCode];

  if (!countryConfig) {
    return false;
  }

  const languages = Object.keys(countryConfig);

  return languages.includes(languageCode);
};

import { useContext } from 'react';

import ListContext from './ListContext';

export const useListContext = () => {
  const context = useContext(ListContext);

  if (context === undefined) {
    throw new Error(
      'The child element must be called from a descendent of the <List/>'
    );
  }

  return context;
};

import { IconType } from '@checkout-ui/internal-svgs';
import { Error } from '@checkout-ui/internal-svgs/Error';
import { Info } from '@checkout-ui/internal-svgs/Info';
import { Send } from '@checkout-ui/internal-svgs/Send';
import clsx from 'clsx';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { BodyText } from '../BodyText';
import { Icon } from '../Icon';
import styles from './Banner.module.scss';

type BannerVariantTypes = 'default' | 'info' | 'infoNeutral' | 'error';

export interface BannerProps {
  className?: string;
  dataTest?: string;
  variant?: BannerVariantTypes;
  icon?: IconType;
  title?: ReactNode;
}

const bannerIcon: Partial<Record<BannerVariantTypes, IconType>> = {
  info: Info,
  infoNeutral: Send,
  error: Error,
  default: Info,
};

export const Banner: FC<PropsWithChildren<BannerProps>> = ({
  variant = 'default',
  dataTest = `banner-${variant}`,
  icon,
  className,
  title,

  children,
  ...props
}) => {
  const iconSource = icon || bannerIcon[variant];

  return (
    <section
      {...props}
      data-test={dataTest}
      className={clsx(styles.banner, styles[variant], className)}
    >
      {title && (
        <BodyText
          fontWeight="semi-bold"
          className={styles['banner__title']}
          dataTest={`${dataTest}-title`}
        >
          {typeof iconSource !== 'undefined' && (
            <Icon
              icon={iconSource}
              size="sm"
              className={clsx(styles['banner__icon'], `banner__icon-${variant}`)}
            />
          )}

          {title}
        </BodyText>
      )}
      {children}
    </section>
  );
};

import { IconType } from './types';

export const Desktop: IconType = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.75 23.25H8.25L9 18.75H15L15.75 23.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 23.25H18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 14.75H23.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 0.75H3.75C2.09315 0.75 0.75 2.09315 0.75 3.75V15.75C0.75 17.4069 2.09315 18.75 3.75 18.75H20.25C21.9069 18.75 23.25 17.4069 23.25 15.75V3.75C23.25 2.09315 21.9069 0.75 20.25 0.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import clsx from 'clsx';
import { forwardRef, PropsWithChildren, ReactNode } from 'react';

import styles from './Dropdown.module.scss';
import { Overlay } from './Overlay';

export interface DropdownProps {
  isExpanded: boolean;
  dataTest?: string;
  trigger: ReactNode;
  className?: string;
  overlayClassName?: string;
}

export const Dropdown = forwardRef<
  HTMLDivElement,
  PropsWithChildren<DropdownProps>
>((props, ref) => {
  const {
    children,
    trigger,
    isExpanded = false,
    dataTest = 'ds-dropdown',
    className = '',
    overlayClassName = '',
  } = props;

  return (
    <div
      ref={ref}
      className={clsx(styles['dropdown'], className)}
      data-test={dataTest}
    >
      <div data-test={`${dataTest}-trigger-wrapper`}>{trigger}</div>
      <Overlay
        className={overlayClassName}
        visible={isExpanded}
        dataTest={`${dataTest}-overlay`}
      >
        {children}
      </Overlay>
    </div>
  );
});

export default Dropdown;

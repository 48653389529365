import { IconType } from './types';

export const PreviousArrow: IconType = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 4.49805L0.75 9.74805L6 14.998"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 9.74805H21.75C22.1478 9.74805 22.5294 9.90608 22.8107 10.1874C23.092 10.4687 23.25 10.8502 23.25 11.248V20.248"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

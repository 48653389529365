import clsx from 'clsx';
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';

import styles from '../InputElement.module.scss';

type SharedInputElementProps = {
  dataTest?: string;
};

export type NonEditableInputElementProps = SharedInputElementProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'placeholder' | 'value'> & {
    placeholder?: ReactNode;
    value?: ReactNode;
    fontFamily?: 'primary' | 'inherit';
  };

const elementBlockStyle = 'input-element';

export const NonEditableField = forwardRef<
  HTMLButtonElement,
  NonEditableInputElementProps
>(
  (
    {
      value,
      dataTest = `non-editable-input`,

      onClick,
      onChange,
      onFocus,
      onBlur,
      className,
      placeholder,
      fontFamily = 'primary',

      ...props
    },
    ref
  ) => (
    <button
      ref={ref}
      {...props}
      data-test={dataTest}
      className={clsx(
        styles[elementBlockStyle],
        styles[`${elementBlockStyle}--${fontFamily}`],
        className
      )}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {value || placeholder}
    </button>
  )
);

NonEditableField.displayName = 'NonEditableField';

import { USER_SCENARIOS } from './constants';

export type CallbackType<MessageType> = (
  event: MessageEvent<MessageType>
) => void;

export interface Config extends FrameBusOptions {
  persistedTopics?: Topic[];
}

type FrameBusOptions = {
  channel?: string;
  origin?: string;
  targetFrames?: IFrameOrWindowList;
};
type IFrameOrWindowList = Array<HTMLIFrameElement | Window>;

export type Topic = string;
export type Message = Record<string, unknown>;
export type OnMessageFn<T extends Message> = (message: T) => void;

export interface CrossDocumentMessengerInterface {
  init: (config: Config) => boolean;
  publish: (topic: Topic, message: Message) => void;
  subscribe: <T extends Message>(
    topic: Topic,
    onMessage: OnMessageFn<T>
  ) => void;
  unsubscribe: <T extends Message>(
    topic: Topic,
    onMessage: OnMessageFn<T>
  ) => void;
  destroy: () => void;
}

export type Intent = keyof typeof USER_SCENARIOS;

export type PreDefinedScenario = {
  translationKey: string;
  intent: Intent;
};

export enum TOPICS {
  set_locale = 'SET_LOCALE',
  intent = 'INTENT',
  authorize = 'AUTHORIZE',
  close = 'CLOSE',
  result = 'RESULT',
  error = 'ERROR',
  place_order = 'PLACE-ORDERER',
}
export type PLACE_ORDER_MESSAGE = 'place-order:success' | 'place-order:failure';

export type EventName = 'authorization_result_change';

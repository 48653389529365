import { isDev } from '@checkout-ui/shared/utils';
import { logger } from '@checkout-ui/shared-logger';

import { SentryType } from './types';
import { getNewRelicOptions, getSentryOptions } from './utils';

export const initTracing = async (
  sentryInstance: SentryType,
  newRelicApplicationID?: string
) => {
  if (typeof window === 'undefined') {
    logger.info('initTracing called on server');
    return;
  }
  const sentryOptions = getSentryOptions();

  logger.info(
    `init sentry called on client with Options: ${JSON.stringify(
      sentryOptions,
      null,
      2
    )}`
  );

  sentryInstance.init(sentryOptions);

  if (newRelicApplicationID && !isDev()) {
    try {
      /**
       * newRelic Browser agent does not support SSR
       * https://github.com/newrelic/newrelic-browser-agent/issues/802
       *
       * !IMPORTANT: keep the import from the src to allow webpack transpilation
       * newrelic browser agent is not compatible with older browsers
       * to avoid issues we need to run our own rules on the package
       */
      const { BrowserAgent } = await import(
        '@newrelic/browser-agent/src/loaders/browser-agent'
      );
      const newRelicOptions = getNewRelicOptions(newRelicApplicationID);
      logger.info(
        `init newRelic called with options: ${JSON.stringify(newRelicOptions)}`
      );
      // The agent loader code executes immediately on instantiation.
      new BrowserAgent(newRelicOptions);
    } catch (error) {
      logger.error(error);
    }
  }
};

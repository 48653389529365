import { IconType } from './types';

export const Send: IconType = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.1903 18.1677L13.6142 21.5759C13.8243 21.7912 14.087 21.9479 14.3762 22.0306C14.6655 22.1133 14.9713 22.1189 15.2634 22.0471C15.5573 21.9787 15.8288 21.836 16.0516 21.6324C16.2744 21.4288 16.441 21.1714 16.5356 20.8849L22.1584 4.03203C22.2757 3.71627 22.2999 3.37345 22.2282 3.04432C22.1564 2.71517 21.9917 2.41359 21.7533 2.17539C21.5152 1.93718 21.2136 1.77238 20.8844 1.70058C20.5554 1.62878 20.2125 1.65299 19.8967 1.77034L3.04394 7.39317C2.74748 7.49443 2.48344 7.67303 2.27911 7.91049C2.07478 8.14795 1.93758 8.43567 1.88167 8.74391C1.82396 9.02425 1.83681 9.31454 1.91905 9.58869C2.0013 9.86283 2.15036 10.1123 2.35286 10.3145L6.65637 14.618L6.51501 20.0682L10.1903 18.1677Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6558 2.11719L6.65625 14.6194"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

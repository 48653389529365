import {
  CountryType,
  SupportedLanguage,
  SupportedLocaleTag,
} from '@checkout-ui/shared-domain-entities';

import { DEFAULT_COUNTRY_SUPPORTED_LANGUAGES_FOR_UI } from './constants/defaultLocaleValue';
import { HPP_STRIPE_COUNTRY_SUPPORTED_LANGUAGES_FOR_UI } from './constants/hppLocaleValue';
import { getAvailableLocalesList } from './getAvailableLocalesList';
import { getLocaleTag } from './getLocaleTag';
import { isSupportedLanguage } from './isSupportedLanguage';

type LocaleSources = {
  countryCode?: CountryType | null;
  urlQueryLocaleCode?: string | null;
  sessionLocaleCode?: string | null;
  showCoreLanguages: boolean;
};

export const detectLocale = (
  {
    countryCode,
    urlQueryLocaleCode,
    sessionLocaleCode,
    showCoreLanguages,
  }: LocaleSources,
  fallbackLocaleTag: SupportedLocaleTag = SupportedLocaleTag.German
): SupportedLocaleTag => {
  const availableLocales = getAvailableLocalesList(countryCode);

  if (showCoreLanguages) {
    fallbackLocaleTag = SupportedLocaleTag.AmericanEnglish;
  }

  // if we don't have country specified, we choose the first exact locale tag match
  if (!countryCode) {
    const possibleLocaleCodes: (string | undefined | null)[] = [
      urlQueryLocaleCode,
      sessionLocaleCode,
      ...navigator.languages,
    ];

    const foundLocaleTag = possibleLocaleCodes.find(
      (localeCode): localeCode is SupportedLocaleTag =>
        !!localeCode && (availableLocales as string[]).includes(localeCode)
    );

    return foundLocaleTag || fallbackLocaleTag;
  }

  if (urlQueryLocaleCode) {
    const urlQueryLanguageCode = new Intl.Locale(urlQueryLocaleCode).language;

    // we will use the language from the provided locale only if it is supported,
    // and will deduce actual locale tag from the language of the provided locale
    if (
      isSupportedLanguage(countryCode, urlQueryLanguageCode, showCoreLanguages)
    ) {
      return getLocaleTag(
        countryCode,
        urlQueryLanguageCode,
        fallbackLocaleTag,
        showCoreLanguages
      );
    }
  }

  // expecting session to contain full locale name from the list of supported locales,
  // thus not trying to guess by session language here
  if (
    sessionLocaleCode &&
    (availableLocales as string[]).includes(sessionLocaleCode)
  ) {
    return sessionLocaleCode as SupportedLocaleTag;
  }

  const userPreferredLanguageCodes = navigator.languages.map(
    (localeCode) => new Intl.Locale(localeCode).language
  );

  const firstPreferredSupportedLanguage = userPreferredLanguageCodes.find(
    (languageCode): languageCode is SupportedLanguage => {
      return isSupportedLanguage(countryCode, languageCode, showCoreLanguages);
    }
  );

  if (firstPreferredSupportedLanguage) {
    return getLocaleTag(
      countryCode,
      firstPreferredSupportedLanguage,
      fallbackLocaleTag,
      showCoreLanguages
    );
  }

  if (showCoreLanguages) {
    return (
      HPP_STRIPE_COUNTRY_SUPPORTED_LANGUAGES_FOR_UI[countryCode].defaultValue ||
      fallbackLocaleTag
    );
  } else {
    return (
      DEFAULT_COUNTRY_SUPPORTED_LANGUAGES_FOR_UI[countryCode].defaultValue ||
      fallbackLocaleTag
    );
  }
};

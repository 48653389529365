import {
  CountryType,
  SupportedLanguage,
  SupportedLocaleTag,
} from '@checkout-ui/shared-domain-entities';

import { DEFAULT_COUNTRY_LANGUAGE_LOCALE } from './constants/defaultLocaleValue';
import { HPP_STRIPE_COUNTRY_LANGUAGE_LOCALE } from './constants/hppLocaleValue';

export const getLocaleTag = (
  countryCode: CountryType,
  languageCode: SupportedLanguage,
  defaultLocaleTag: SupportedLocaleTag = SupportedLocaleTag.German,
  showCoreLanguages: boolean
): SupportedLocaleTag => {
  const countryLocales = showCoreLanguages
    ? HPP_STRIPE_COUNTRY_LANGUAGE_LOCALE[countryCode]
    : DEFAULT_COUNTRY_LANGUAGE_LOCALE[countryCode];

  if (!countryLocales) {
    return defaultLocaleTag;
  }

  const locale = countryLocales[languageCode];

  if (!locale) {
    return defaultLocaleTag;
  }

  return locale;
};

import { isProdKlarna, isStaging } from '@checkout-ui/shared/utils';

const SEGMENT_KEY_STAGING = 'KC9rHVot8R6pjGG3NuRVgEnA8CbJZfNV';
const SEGMENT_KEY_PROD = 'Y7OlGyFDm7qr1HyutHYiph1vus5vJGUY';

declare global {
  interface Window {
    _fs_is_outer_script?: boolean;
  }
}
//FIXME:Temp solution till https://ozean12.atlassian.net/browse/CHECK-142 is solved
export const initSegment = (customKey?: string) => {
  let key = '';

  if (typeof window !== 'undefined') {
    // to enable FS to record in iframe
    // https://help.fullstory.com/hc/en-us/articles/360020622514-Can-FullStory-capture-content-that-is-presented-in-iframes-#h_01F1G33B40Q2TPQA8MA7SF8Y5P
    window['_fs_is_outer_script'] = true;
  }

  if (isStaging()) {
    key = SEGMENT_KEY_STAGING;
  }
  if (isProdKlarna()) {
    key = SEGMENT_KEY_PROD;
  }
  if (customKey) {
    key = customKey;
  }
  if (key) {
    return `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey='${key}';;analytics.SNIPPET_VERSION="4.15.3";
  analytics.load('${key}');
  analytics.page();
  }}();`;
  }
  return key;
};

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ViewportContextType,ViewportType } from './types';

export const ViewportContext = createContext<ViewportContextType | undefined>(
  undefined
);

ViewportContext.displayName = 'ViewportContext';

const initialValues: ViewportType = ViewportType.DESKTOP;

export const ViewportContextProvider = ({
  children,
}: PropsWithChildren<Record<never, never>>) => {
  const [viewport, setViewport] = useState<ViewportType>(initialValues);
  const [isNavShown, setIsNavShown] = useState<boolean>(false);
  const handleViewportChange = useCallback(
    (value: ViewportType) => {
      setViewport(value);
      return;
    },

    [setViewport]
  );

  const toggleNav = useCallback(
    () => setIsNavShown((isNavShown) => !isNavShown),
    []
  );
  const viewportValues = useMemo(
    () => ({
      viewport,
      handleViewportChange,
      toggleNav,
      isNavShown,
    }),
    [viewport, handleViewportChange, toggleNav, isNavShown]
  );
  return (
    <ViewportContext.Provider value={viewportValues}>
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewportToggler = () => {
  const context = useContext(ViewportContext);

  if (context === undefined) {
    throw new Error(
      `useViewportToggler must be used within a ViewportContextProvider`
    );
  }

  return context;
};

export default ViewportContextProvider;

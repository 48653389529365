import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes } from 'react';

import styles from '../InputElement.module.scss';

type SharedInputElementProps = {
  id: string;
  name: string;
  value: string;
  dataTest?: string;
  fontFamily?: 'primary' | 'inherit';
};

export type InputElementProps = InputHTMLAttributes<HTMLInputElement> &
  SharedInputElementProps;

const elementBlockStyle = 'input-element';

export const InputElement = forwardRef<HTMLInputElement, InputElementProps>(
  (
    {
      id,
      name,
      value,
      type = 'text',
      dataTest = `${name}-input`,

      onClick,
      onChange,
      onFocus,
      onBlur,
      className,
      fontFamily = 'primary',

      ...props
    },
    ref
  ) => {
    return (
      <input
        {...props}
        ref={ref}
        id={id}
        name={name}
        type={type}
        value={value}
        data-test={dataTest}
        className={clsx(
          styles[elementBlockStyle],
          styles[`${elementBlockStyle}--${fontFamily}`],
          styles[`${elementBlockStyle}--editable`],
          className
        )}
        onClick={onClick}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  }
);

InputElement.displayName = 'InputElement';

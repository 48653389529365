import { NextjsOptions } from '@sentry/nextjs/esm/utils/nextjsOptions';

export type SentryOptions = NextjsOptions; // nextjs options extend react options so it works for both

export enum SentryEnv {
  dev = 'Development',
  prod = 'Production',
  stg = 'Staging',
}

export type SentryType = {
  init: (options: SentryOptions) => void;
};

import { createContext } from 'react';

import { CrossDocumentMessengerInterface } from './types';

export const CrossDocumentMessengerContext = createContext<
  | {
      crossDocumentMessenger: CrossDocumentMessengerInterface;
      isReady: boolean;
    }
  | undefined
>(undefined);

CrossDocumentMessengerContext.displayName = 'CrossDocumentMessengerContext';

import clsx from 'clsx';
import { forwardRef, HTMLAttributes, ReactNode } from 'react';

import {
  NonEditableInputBase,
  NonEditableInputBaseProps,
} from '../../InputBase';
import { InputLabel } from '../../InputLabel';
import { Text } from '../../Text';
import { AnimatedChevron } from './AnimatedChevron';
import styles from './Trigger.module.scss';

type TriggerProps = NonEditableInputBaseProps & {
  isOpen: boolean;
  label: string | ReactNode;
  dataTest: string;

  labelProps?: HTMLAttributes<HTMLLabelElement>;
};

export const Trigger = forwardRef<HTMLButtonElement, TriggerProps>(
  (
    {
      isOpen,
      id = 'de-select-trigger',
      name = 'de-select-trigger',
      value,
      placeholder,
      label,
      labelProps,
      dataTest,
      validationState,

      ...otherProps
    },
    ref
  ) => {
    const isError = validationState === 'error';

    return (
      <div
        className={styles['input-container']}
        data-test={`${dataTest}-container`}
      >
        <InputLabel
          dataTest={`${dataTest}-label`}
          htmlFor={id}
          className={clsx(styles.label)}
          {...labelProps}
        >
          <Text
            tag="p"
            className={styles['label_text']}
            color={isError ? 'error--dark' : 'text--inherit'}
          >
            {label}
          </Text>

          <NonEditableInputBase
            role="button"
            isActive={isOpen}
            id={id}
            name={name}
            value={value}
            placeholder={placeholder}
            inputAdornments={{
              endAdornment: <AnimatedChevron isOpen={isOpen} />,
            }}
            ref={ref}
            validationState={validationState}
            dataTest={dataTest}
            {...otherProps}
          />
        </InputLabel>
      </div>
    );
  }
);

Trigger.displayName = 'SelectTrigger';

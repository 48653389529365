import { IconType } from '@checkout-ui/internal-svgs';

import styles from './RadioIcon.module.scss';

export const RadioIconUnselected: IconType = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test="radio-icon--unselected"
    >
      <circle
        cx="10"
        cy="10"
        r="9.5"
        className={styles['radio-icon__circle--big--unselected']}
      />
    </svg>
  );
};

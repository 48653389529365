
const memoizedNames: Record<string, string> = {};

export const getLocalLanguageName = (localeTag: string): string => {
  if (memoizedNames[localeTag]) {
    return memoizedNames[localeTag];
  }

  const languageNames = new Intl.DisplayNames([localeTag], { type: 'language' });
  memoizedNames[localeTag] = languageNames.of(localeTag) || localeTag;

  return memoizedNames[localeTag];
};

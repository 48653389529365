import {
  CountryType,
  SupportedLanguage,
  SupportedLocaleTag,
} from '@checkout-ui/shared-domain-entities';

import type {
  CountryLanguageLocale,
  CountrySupportedLanguageForUI,
} from '../types';

export const HPP_STRIPE_COUNTRY_LANGUAGE_LOCALE: CountryLanguageLocale = {
  [CountryType.AT]: {
    [SupportedLanguage.German]: SupportedLocaleTag.AustrianGerman,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
  },
  [CountryType.CH]: {
    [SupportedLanguage.German]: SupportedLocaleTag.SwissGerman,
    [SupportedLanguage.French]: SupportedLocaleTag.French,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
  },
  [CountryType.DE]: {
    [SupportedLanguage.German]: SupportedLocaleTag.German,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
    [SupportedLanguage.Dutch]: SupportedLocaleTag.Dutch,
    [SupportedLanguage.French]: SupportedLocaleTag.French,
  },
  [CountryType.FI]: {
    [SupportedLanguage.Finnish]: SupportedLocaleTag.Finnish,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
  },
  [CountryType.FR]: {
    [SupportedLanguage.French]: SupportedLocaleTag.French,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
    [SupportedLanguage.German]: SupportedLocaleTag.German,
    [SupportedLanguage.Dutch]: SupportedLocaleTag.Dutch,
  },
  [CountryType.GB]: {
    [SupportedLanguage.English]: SupportedLocaleTag.BritishEnglish,
  },
  [CountryType.NL]: {
    [SupportedLanguage.Dutch]: SupportedLocaleTag.Dutch,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
    [SupportedLanguage.German]: SupportedLocaleTag.German,
    [SupportedLanguage.French]: SupportedLocaleTag.French,
  },
  [CountryType.NO]: {
    [SupportedLanguage.Norwegian]: SupportedLocaleTag.Norwegian,
    [SupportedLanguage.NorwegianBokmal]: SupportedLocaleTag.Norwegian,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
  },
  [CountryType.SE]: {
    [SupportedLanguage.Swedish]: SupportedLocaleTag.Swedish,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
  },
};

export const HPP_STRIPE_COUNTRY_SUPPORTED_LANGUAGES_FOR_UI: CountrySupportedLanguageForUI =
  {
    [CountryType.AT]: {
      options: [
        { value: SupportedLocaleTag.AustrianGerman, displayNameCode: 'de' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.AustrianGerman,
    },
    [CountryType.CH]: {
      options: [
        { value: SupportedLocaleTag.SwissGerman, displayNameCode: 'de' },
        { value: SupportedLocaleTag.French, displayNameCode: 'fr' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.SwissGerman,
    },
    [CountryType.DE]: {
      options: [
        { value: SupportedLocaleTag.German, displayNameCode: 'de' },
        { value: SupportedLocaleTag.Dutch, displayNameCode: 'nl' },
        { value: SupportedLocaleTag.French, displayNameCode: 'fr' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.German,
    },
    [CountryType.FI]: {
      options: [
        { value: SupportedLocaleTag.Finnish, displayNameCode: 'fi' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.Finnish,
    },
    [CountryType.FR]: {
      options: [
        { value: SupportedLocaleTag.French, displayNameCode: 'fr' },
        { value: SupportedLocaleTag.German, displayNameCode: 'de' },
        { value: SupportedLocaleTag.Dutch, displayNameCode: 'nl' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.French,
    },
    [CountryType.GB]: {
      options: [
        { value: SupportedLocaleTag.BritishEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.BritishEnglish,
    },
    [CountryType.NL]: {
      options: [
        { value: SupportedLocaleTag.Dutch, displayNameCode: 'nl' },
        { value: SupportedLocaleTag.German, displayNameCode: 'de' },
        { value: SupportedLocaleTag.French, displayNameCode: 'fr' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.Dutch,
    },
    [CountryType.NO]: {
      options: [
        { value: SupportedLocaleTag.Norwegian, displayNameCode: 'nb' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.Norwegian,
    },
    [CountryType.SE]: {
      options: [
        { value: SupportedLocaleTag.Swedish, displayNameCode: 'sv' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.Swedish,
    },
  };

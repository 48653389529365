import { PropsWithChildren, useMemo } from 'react';

import ListContext from './ListContext';

function ListContextProvider({
  dense,
  hasDividers,
  children,
}: PropsWithChildren<{ dense: boolean ,hasDividers: boolean }>) {
  const context = useMemo(() => ({ dense, hasDividers }), [dense, hasDividers]);
  return (
    <ListContext.Provider value={context}>{children}</ListContext.Provider>
  );
}

export default ListContextProvider;

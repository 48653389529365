import {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CrossDocumentMessengerContext } from './context';
import crossDocumentMessenger from './messenger';
import { Config } from './types';

export const CrossDocumentMessengerProvider = ({
  config,
  children,
}: PropsWithChildren<{ config?: Config }>) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const initialized = crossDocumentMessenger.init(config);

    setIsReady(initialized);
  }, [config]);

  const contextValue = useMemo(() => {
    return { crossDocumentMessenger, isReady };
  }, [isReady]);

  return (
    <CrossDocumentMessengerContext.Provider value={contextValue}>
      {children}
    </CrossDocumentMessengerContext.Provider>
  );
};

export const useCrossDocumentMessenger = () => {
  const context = useContext(CrossDocumentMessengerContext);

  if (context === undefined) {
    throw new Error(
      `useCrossDocumentMessenger must be used within a CrossDocumentMessengerProvider`
    );
  }

  return context;
};

import clsx from 'clsx';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import { ColorPalette } from '../global-styles/types';
import styles from './Text.module.scss';

type CustomColor =
  | 'grey--medium'
  | 'grey--dark'
  | 'text--black'
  | 'text--inherit'
  | 'text--info'
  | 'grey--darker'
  | 'error--dark';

export interface TextBaseProps {
  color?: ColorPalette | CustomColor;

  fontWeight?: 'regular' | 'semi-bold' | 'bold';
  fontFamily?: 'primary' | 'inherit';
  dataTest?: string;
  className?: string;
}

export type TextAsHeading = TextBaseProps &
  Omit<HTMLAttributes<HTMLHeadingElement>, keyof TextBaseProps> & {
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  };

export type TextAsParagraph = TextBaseProps &
  Omit<HTMLAttributes<HTMLParagraphElement>, keyof TextBaseProps> & {
    tag?: 'p';
  };

export type TextAsSpan = TextBaseProps &
  Omit<HTMLAttributes<HTMLSpanElement>, keyof TextBaseProps> & {
    tag?: 'span';
  };

export type TextProps = TextAsHeading | TextAsParagraph | TextAsSpan;

export const Text: FC<PropsWithChildren<TextProps>> = ({
  tag: Element = 'span' as React.ElementType,
  dataTest = 'text',
  color = 'text--inherit',
  fontWeight = 'regular',
  fontFamily = 'primary',
  children,
  className,
  ...props
}) => {
  return (
    <Element
      className={clsx(
        styles['text'],
        color && styles[color],
        fontWeight && styles[fontWeight],
        fontFamily && styles[`${fontFamily}-font-family`],
        className
      )}
      data-test={dataTest}
      {...props}
    >
      {children}
    </Element>
  );
};
export default Text;

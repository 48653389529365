import { IconType } from './types';

export const Chevron: IconType = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.293 6.293a1 1 0 011.414 0l9.53 9.53 9.53-9.53a1 1 0 111.415 1.414L12.945 17.945a1 1 0 01-1.415 0L1.293 7.707a1 1 0 010-1.414z"
      />
    </svg>
  );
};
